import * as React from "react";

import Map from "./Map";

class AirQualityMap extends React.Component {
  render() {
    return (
      <div>
       <Map/>
      </div>
    );
  }
}

export default AirQualityMap;
